import React from "react"
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Seo from "../components/SEO"


const page404 = () => {
    return (
        <>
            <Seo title="404" />
            <Container style={{
                paddingTop: "calc(100px + 10vh)",
                marginBottom: "50vh"
            }}>
                <h1>Page not found</h1>
                <h2>The page you are looking for does not exist.</h2>
                <Link to="/">Go back</Link>
            </Container>
        </>
    );
}

export default page404